// This mixin manages device-related properties and breakpoints.

const MOBILE_WIDTH = 1024; // Define the mobile width breakpoint

export default {
  data() {
    return {
      isMobile: false, // Indicates if the device is in mobile view
      isSystemMacOS: true, // Indicates if the device runs macOS
      isSystemHuawei: true, // Indicates if the device is a Huawei device
      isSystemOther: true, // Indicates if the device is neither macOS nor Huawei
    };
  },

  async mounted() {
    // Add a resize event listener to handle breakpoints
    window.addEventListener("resize", this.breakpoints, {
      passive: true,
    });

    // Call the breakpoints method to initialize device properties
    await this.breakpoints();

    // Call the operatingSystem method to determine the operating system
    this.operatingSystem();
  },

  beforeDestroy() {
    // Remove the resize event listener when the component is destroyed
    window.removeEventListener("resize", this.breakpoints, {
      passive: true,
    });
  },

  methods: {
    // Check and update the 'isMobile' property based on the window width
    breakpoints() {
      this.isMobile = window.innerWidth < MOBILE_WIDTH;
    },

    // Determine the operating system based on user-agent information
    operatingSystem() {
      if (this.isMobile) {
        this.isSystemMacOS = navigator.userAgent.indexOf("Mac") > 0;
        this.isSystemHuawei = navigator.userAgent.indexOf("Huawei") > 0;
        this.isSystemOther = !this.isSystemMacOS && !this.isSystemHuawei;
      }
    },
  },
};
