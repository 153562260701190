
import lang from "@/mixins/lang";

// Static Data Sayfa Ayarları
import site_settings from "@/mixins/site-settings";

export default {
  name: "dynamic",
  mixins: [lang, site_settings],
  data() {
    return {
      menu: {
        header: null,
        footer: null,
        bottom: null,
      },
      notification: {},
    };
  },
  async fetch() {
    await this.fetchMenus();
  },
  methods: {
    async fetchMenus() {
      try {
        const lang = this.currentLang ?? "tr";
        const [headerData, footerData, bottomData, notification] =
          await Promise.all([
            this.$axios.get(
              `navigation/render/main-navigation?locale=${lang}&type=TREE`
            ),
            this.$axios.get(
              `navigation/render/footer-navigation?locale=${lang}&type=TREE`
            ),
            this.$axios.get(
              `navigation/render/bottom-navigation?locale=${lang}&type=TREE`
            ),
            this.$axios.get(
              `bildirimler?populate=*&sort=publishedAt:desc&pagination[limit]=1&locale=${lang}`
            ),
          ]);
        this.menu.header = headerData?.data ?? null;
        this.menu.footer = footerData?.data ?? null;
        this.menu.bottom = bottomData?.data ?? null;
        this.notification = notification?.data?.data?.[0]?.attributes ?? null;
      } catch (error) {
        console.log(error);
      }
    },
  },
  watch: {
    "$route.path": {
      handler() {
        this.routeLang = this.$utils.helper.getLangFromPath(this.$route.path);
        this.fetchMenus();
      },
      immediate: true,
    },
  },
};
