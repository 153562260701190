
export default {
  name: "Modal",
  props: {
    featuredMedia: {
      type: Object,
    },
    videoEmbedUrl: {
      type: String,
    },
    video: {
      type: Object,
    },
    modalStatus: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    videoOptions() {
      return {
        autoplay: false,
        controls: true,
        poster: `${process.env.NUXT_ENV_CDN_URL}${
          this.featuredMedia?.url ?? ""
        }`,
        sources: [
          {
            src: `${process.env.NUXT_ENV_CDN_URL}${this.video?.url ?? ""}`,
            type: `${this.video?.mime ?? ""}`,
          },
        ],
      };
    },
  },
  methods: {
    // Function to hide the modal
    hideModal() {
      // Emit events to update modalStatus and videoEmbedUrl
      this.$emit("update:modalStatus", false);
      this.$emit("update:videoEmbedUrl", null);
      this.$emit("update:video", null);
      this.$emit("update:featuredMedia", null);
    },
  },
};
