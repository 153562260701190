
export default {
  name: "Picture",
  props: {
    image: {
      type: Object,
      default() {},
    },
  },
  data() {
    return {
      cdnUrl: "",
    };
  },
  created() {
    this.cdnUrl = process.env.NUXT_ENV_CDN_URL;
  },
};
