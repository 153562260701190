
import lang from "@/mixins/lang.js";
import device from "@/mixins/device.js";
export default {
  name: "Footer",
  mixins: [lang, device],
  props: {
    footer: {
      type: Object,
    },
    global: {
      type: Object,
    },
    menu: {
      type: Array,
    },
    bottomMenu: {
      type: Array,
    },
    isLangActive: {
      type: Boolean,
    },
  },
};
