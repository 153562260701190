// Import necessary modules
import axios from 'axios';
import { cacheAdapterEnhancer, throttleAdapterEnhancer } from 'axios-extensions';

// Create an instance of Axios with custom settings
const http = axios.create({
  baseURL: process.env.NUXT_ENV_API_URL, // Set the base URL for API requests
  headers: {
    'Cache-Control': 'max-age=3600000', // Cache responses for 1 hour
    'Access-Control-Allow-Origin': '*', // Allow requests from any origin
    'Access-Control-Allow-Methods': 'GET', // Allow only GET requests
    'Access-Control-Allow-Headers': 'cache-control', // Allow the cache-control header
    'Content-Type': 'application/json, text/plain, /', // Allow JSON, text, and other formats
    'Referrer-Policy': 'strict-origin-when-cross-origin', // Set Referrer-Policy policy
  },
  adapter: throttleAdapterEnhancer(cacheAdapterEnhancer(axios.defaults.adapter)), // Enhance adapter for request throttling and caching
});

export default function ({ $axios }) {
  // Set $axios default settings based on the custom http instance
  $axios.defaults.baseURL = http.defaults.baseURL; // Set $axios's default baseURL to match http's baseURL
  $axios.interceptors.request.use(config => {
    const token = process.env.NUXT_ENV_AUTH_TOKEN;
    if (token) {
      config.headers.Authorization = `Bearer ${token}`; // Add authentication token to request header
    }
    return config;
  });
  // * Currently, we don't need to set $axios.defaults.headers based on http.defaults.headers.
  // $axios.defaults.headers = http.defaults.headers;
  $axios.defaults.adapter = http.defaults.adapter; // Set $axios's default adapter to match http's adapter
}
