import Helper from "./helper";

export default function(context, inject) {
  // Initialize API factories
  const factories = {
    helper: Helper(context.i18n),
  };

  // Inject $utils
  inject("utils", factories);
}
