// Import Vue and the VueSlickCarousel component
import Vue from 'vue'
import VueSlickCarousel from 'vue-slick-carousel'

// Register the VueSlickCarousel component globally
Vue.component('VueSlickCarousel', VueSlickCarousel);

// This plugin allows you to use the VueSlickCarousel component throughout your Vue.js application.
// VueSlickCarousel is a Vue.js wrapper for the Slick Carousel library, which provides a carousel/slider component.
// Registering it globally allows you to use it in any Vue component without the need for additional imports.
