
import lang from "@/mixins/lang.js";

export default {
  name: "Breadcrumb",
  mixins: [lang],
  data() {
    return {
      breadcrumbs: [],
    };
  },
  watch: {
    $route(to, from) {
      this.getBreadcrumbs();
    },
  },
  mounted() {
    this.getBreadcrumbs();
  },
  methods: {
    getBreadcrumbs() {
      const title = document.getElementsByTagName("title");
      const matchedRoutes = this.$route.matched;
      const breadcrumbs = [];
      let urlPath = "";
      for (let i = 0; i < matchedRoutes.length; i++) {
        const route = matchedRoutes[i];
        if (route.path !== "") {
          const nameParts = route.name.split("/");
          for (let j = 0; j < nameParts.length; j++) {
            const namePart = nameParts[j];
            urlPath += "/" + route.path.slice(1).split("/")[j];
            breadcrumbs.push({
              label:
                namePart.replace(/___tr$|___en$/, "") == "slug"
                  ? title[0].innerText
                  : namePart.replace(/___tr$|___en$/, ""),
              url: urlPath,
            });
          }
        }
      }
      this.breadcrumbs = breadcrumbs;
    },
  },
};
