
export default {
  name: "Notification",
  props: {
    notification: {
      type: Object,
      default() {},
    },
  },
  data() {
    return {
      notificationState: false,
      showModal: false,
      videoEmbedUrl: null,
    };
  },
  computed: {
    shouldShowNotification() {
      return !!this.notification && this.notificationState;
    },
  },
  mounted() {
    this.notificationState = sessionStorage.getItem("iski_notification");
  },
  methods: {
    openModal(embedUrl) {
      this.videoEmbedUrl = this.videoEmbedUrl = embedUrl
        ? embedUrl.replace("watch?v=", "embed/")
        : null;
      this.showModal = true;
    },
    hideNotification() {
      sessionStorage.setItem("iski_notification", "false");
      this.notificationState = false;
    },
  },
};
