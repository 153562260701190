
export default {
  name: "Weather",
  data() {
    return {
      weather: {},
    };
  },
  async fetch() {
    try {
      const { data } = await this.$axios.get(`weather`);
      this.weather = data;
    } catch (error) {
      console.log(error);
    }
  },

  computed: {
    icons() {
      switch (this.weather.current_weather.weathercode) {
        case 0:
          return "fa-solid fa-sun-bright";
          break;
        case 1:
          return "fa-solid fa-cloud";
          break;
        case 2:
          return "fa-solid fa-sun-cloud";
          break;
        case 3:
          return "fa-solid fa-cloud";
          break;
        case 45:
          return "fa-solid fa-cloud-fog";
          break;
        case 48:
          return "fa-solid fa-cloud-fog";
          break;
        case 51:
          return "fa-light fa-cloud-drizzle";
          break;
        case 53:
          return "fa-regular fa-cloud-drizzle";
          break;
        case 55:
          return "fa-solid fa-cloud-drizzle";
          break;
        case 56:
          return "fa-light fa-cloud-drizzle";
          break;
        case 57:
          return "fa-solid fa-cloud-drizzle";
          break;
        case 61:
          return "fa-light fa-cloud-showers";
          break;
        case 63:
          return "fa-regular fa-cloud-rain";
          break;
        case 65:
          return "fa-solid fa-cloud-rain";
          break;
        case 66:
          return "fa-light fa-cloud-hail-mixed";
          break;
        case 67:
          return "fa-solid fa-cloud-hail-mixed";
          break;
        case 71:
          return "fa-thin fa-cloud-snow";
          break;
        case 73:
          return "fa-light fa-cloud-snow";
          break;
        case 75:
          return "fa-regular fa-cloud-snow";
          break;
        case 77:
          return "fa-solid fa-cloud-snow";
          break;
        case 80:
          return "fa-light fa-cloud-showers";
          break;
        case 81:
          return "fa-regular fa-cloud-showers";
          break;
        case 82:
          return "fa-solid fa-cloud-showers";
          break;
        case 85:
          return "fa-thin fa-cloud-snow";
          break;
        case 86:
          return "fa-solid fa-cloud-snow";
          break;
        case 95:
          return "fa-light fa-cloud-bolt";
          break;
        case 96:
          return "fa-thin fa-cloud-bolt";
          break;
        case 99:
          return "fa-solid fa-cloud-bolt";
          break;
      }
    },
  },
};
