
import lang from "@/mixins/lang.js";
import device from "@/mixins/device.js";

export default {
  name: "Header",
  mixins: [lang, device],
  props: {
    header: {
      type: Object,
    },
    menu: {
      type: Array,
    },
  },
  data() {
    return {
      activeNavigationIndex: null,
      activeNavigationItem: null,
      activeMenuTitle: true,
      activeSubNavigation: null,
      activeNavigationIcon: false,
      cdnUrl: "",
    };
  },
  watch: {
    $route(to, from) {
      const body = document.querySelector("body");
      const html = document.querySelector("html");
      this.activeNavigationIndex = null;
      this.activeNavigationItem = null;
      this.activeSubNavigation = null;
      this.activeNavigationIcon = false;
      this.activeMenuTitle = true;
      body.classList.remove("overflow-hidden");
      html.classList.remove("orgchart-html-active");
    },
  },
  // TODO: onClickOutsideOfResultBox fonksiyonunda console hatası var. Düzeltilmesi lazım.
  mounted() {
    document.addEventListener("click", this.onClickOutsideOfResultBox);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.onClickOutsideOfResultBox);
  },
  methods: {
    mobileOpenNavigation() {
      this.activeNavigationIcon = true;
      this.disableScroll();
    },
    openNavigation(index) {
      if (!this.isMobile) {
        if (index === this.activeNavigationIndex) {
          this.activeNavigationIndex = null;
          this.activeNavigationItem = null;
        } else {
          this.activeNavigationIndex = index;
          this.activeNavigationItem = index;
        }
        this.disableScroll();
      } else {
        this.activeNavigationItem = index;
      }
    },
    closeNavigation() {
      this.activeNavigationIcon = false;
      this.activeNavigationIndex = null;
      this.activeNavigationItem = null;
      this.activeSubNavigation = null;
      this.activeMenuTitle = true;
      this.enableScroll();
      this.navigationMaskClose();
    },
    openSubNavigation(index) {
      this.activeSubNavigation = index;
      this.activeMenuTitle = false;
    },
    backFromSubNavigation() {
      this.activeSubNavigation = null;
      this.activeMenuTitle = true;
    },
    backNavigation() {
      this.activeNavigationItem = null;
    },
    navigationMaskClose() {
      this.activeNavigationItem = null;
      this.enableScroll();
    },
    disableScroll() {
      document.querySelector("body").classList.add("overflow-hidden");
    },
    enableScroll() {
      document.querySelector("body").classList.remove("overflow-hidden");
    },
  },
  created() {
    this.cdnUrl = process.env.NUXT_ENV_CDN_URL;
  },
};
