
import lang from "@/mixins/lang.js";

export default {
  name: "Button",
  mixins: [lang],
  props: {
    label: {
      type: String,
    },
    url: {
      type: String,
    },
    size: {
      type: String,
      default: "md",
    },
    color: {
      type: String,
      default: "blue",
    },
    icon: {
      type: String,
    },
    iconPosition: {
      type: String,
      default: "left",
    },
    external: {
      type: Boolean,
      default: false,
    },
    enabled: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    icons() {
      switch (this.icon) {
        case "none":
          return "none";
          break;
        case "circle_e":
          return "fa-solid fa-circle-e";
          break;
        case "wallet":
          return "fa-regular fa-wallet";
          break;
        case "scroll":
          return "fa-regular fa-scroll";
          break;
        case "triangle_exclamation":
          return "fa-regular fa-triangle-exclamation";
          break;
        case "building_columns":
          return "fa-regular fa-building-columns";
          break;
        case "hand_holding_droplet":
          return "fa-regular fa-hand-holding-droplet";
          break;
        case "person_circle_check":
          return "fa-regular fa-person-circle-check";
          break;
        case "bag_shopping":
          return "fa-solid fa-bag-shopping";
          break;
        case "apple":
          return "fa-brands fa-apple";
          break;
        case "google_play":
          return "fa-brands fa-google-play";
          break;
        case "angle_down":
          return "fas fa-angle-down";
          break;
        case "angle_left":
          return "fas fa-angle-left";
          break;
        case "angle_right":
          return "fas fa-angle-right";
          break;
        case "angle_up":
          return "fas fa-angle-up";
          break;
        case "arrow_down":
          return "fas fa-arrow-down";
          break;
        case "arrow_down_to_line":
          return "fas fa-arrow-to-bottom";
          break;
        case "arrow_up":
          return "fas fa-arrow-up";
          break;
        case "bars":
          return "fas fa-bars";
          break;
        case "bars_filter":
          return "fas fa-bars-filter";
          break;
        case "bus":
          return "fas fa-bus";
          break;
        case "calendar":
          return "fa-regular fa-calendar";
          break;
        case "camera_movie":
          return "fa-solid fa-camera-movie";
          break;
        case "check":
          return "fas fa-check";
          break;
        case "cloud":
          return "fas fa-cloud";
          break;
        case "facebook":
          return "fa-brands fa-facebook-f";
          break;
        case "file_lines":
          return "fas fa-file-lines";
          break;
        case "hand_holding_heart":
          return "fa-solid fa-hand-holding-heart";
          break;
        case "hands_holding_heart":
          return "fa-solid fa-hands-holding-heart";
          break;
        case "heart":
          return "fas fa-heart";
          break;
        case "instagram":
          return "fa-brands fa-instagram";
          break;
        case "minus":
          return "fas fa-minus";
          break;
        case "long_arrow_right":
          return "fas fa-long-arrow-right";
          break;
        case "paintbrush_pencil":
          return "fa-solid fa-paintbrush-pencil";
          break;
        case "phone":
          return "fas fa-phone";
          break;
        case "play":
          return "fas fa-play";
          break;
        case "search":
          return "fas fa-search";
          break;
        case "search_plus":
          return "fas fa-search-plus";
          break;
        case "square_arrow_up_right":
          return "fa-light fa-arrow-up-right-from-square";
          break;
        case "times":
          return "fas fa-times";
          break;
        case "linkedin":
          return "fa-brands fa-linkedin";
          break;
        case "tiktok":
          return "fa-brands fa-tiktok";
          break;
        case "youtube":
          return "fa-brands fa-youtube";
          break;
        case "twitter":
          return "fa-brands fa-x-twitter";
      }
    },
  },
};
