
import VueMarkdown from "vue-markdown";

export default {
  name: "Markdown",
  components: { VueMarkdown },
  props: {
    source: {
      type: String,
      default: "",
    },
  },
  methods: {
    changeImgProvider(x) {
      return x.split('(/uploads/').join(`(${process.env.NUXT_ENV_CDN_URL}/uploads/`)
    },
  },
};
