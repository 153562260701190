// This mixin handles language-related functionality, including language detection and content localization.

// Import language files for different languages
import en from '@/lang/en.json'; // English language file
import tr from '@/lang/tr.json'; // Turkish language file

export default {
  data() {
    return {
      // Extract the path without the language segment
      path: this.$utils.helper.getPathWithOutLang(this.$route.path),
      // Extract the language code from the route path
      routeLang: this.$utils.helper.getLangFromPath(this.$route.path),
      // userLang: '', // ? Uncomment and set to the browser's language code if desired
    };
  },
  // ? Uncomment this section to detect and set the user's browser language
  // beforeMount() {
  //   this.userLang = languages.find((item) => item.iso === navigator.language)?.iso ?? "";
  // },

  computed: {
    // Determine the current language based on the route or user's browser language
    currentLang() {
      // ? Uncomment the following line if you want to prioritize the browser's language
      // return this.routeLang || this.userLang || "tr";
      return this.routeLang || "tr"; // Default to Turkish if no language detected
    },
    // Generate the path to switch between languages
    switchLangPath() {
      return this.currentLang == 'en' ? '/' : '/en/';
    },
    // Determine the label for the language switcher
    switchLangLabel() {
      return this.currentLang == 'en' ? 'TR' : 'EN';
    },
    // Get the localized content for the current language
    localizationContent() {
      switch (this.currentLang) {
        case 'en':
          return en; // English content
        case 'tr':
          return tr; // Turkish content
        default:
          return tr; // Default to Turkish content
      }
    },
  },
  watch: {
    $route() {
      // Update routeLang whenever the route changes
      this.routeLang = this.$utils.helper.getLangFromPath(this.$route.path);
    },
  },
};
