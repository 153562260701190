import languages from "@/languages.json";

export default () => ({
  getLangFromPath(path) {
    const enControl = path === '/en' ? '/en/' : path
    const lang = enControl.match(/^\/?(\w{2})\//)?.[1] || "";
    return languages.some((value) => lang === value.iso) ? lang : "tr";
  },
  getPathWithOutLang(path) {
    return `/${path
      .split("/")
      .filter((item) => item.length > 0 && item !== this.getLangFromPath(path))
      .join("/")}`;
  },
});
