// This mixin provides site settings data used for various parts of the application, such as the header, footer, SEO, and more.

// Import the site settings data and other required mixins
import site_settings from "@/site-settings.json";
import LANG from "@/mixins/lang.js";
import SEO from "@/mixins/seo.js";

export default {
  mixins: [LANG, SEO], // Include the lang and SEO mixins
  data() {
    return {
      siteSettingsData: {}, // Initialize an empty object to store site settings data
    };
  },
  head() {
    // Define the favicon link in the HTML head based on site settings data
    return {
      link: [
        {
          rel: "icon",
          type: "image/x-icon",
          href: process.env.NUXT_ENV_CDN_URL + this.siteSettingsData?.global?.head?.favicon?.data?.attributes
            ?.url,
        },
      ],
    };
  },
  async mounted() {
    await this.siteSettings(); // Load site settings data when the component is mounted
  },
  methods: {
    // Fetch and set site settings data for the current language
    siteSettings() {
      const data = site_settings.data.find(
        (obj) => obj.locale === this.currentLang
      );
      this.siteSettingsData = {
        header: data?.header ?? null, // Header configuration
        footer: data?.footer ?? null, // Footer configuration
        global: data?.global ?? null, // Global settings
        topbar: data?.topBar ?? null, // Topbar configuration
        dynamicRouteMaps: data?.dynamicRouteMaps ?? null, // Dynamic route maps
      };
    },
  },
};
