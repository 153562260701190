import { render, staticRenderFns } from "./home.vue?vue&type=template&id=4abfb408"
import script from "./home.vue?vue&type=script&lang=js"
export * from "./home.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Notification: require('/usr/app/components/notification/Notification.vue').default,TopBar: require('/usr/app/components/topBar/TopBar.vue').default,Header: require('/usr/app/components/header/Header.vue').default,ButtonUp: require('/usr/app/components/button/ButtonUp.vue').default,Footer: require('/usr/app/components/footer/Footer.vue').default})
