
import lang from "@/mixins/lang.js";

export default {
  name: "error",
  mixins: [lang],
  props: {
    error: {
      type: Object,
      default: () => {
        "error";
      },
    },
  },
  mounted() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  },
};
