// Import Vue and the InstantSearch library
import Vue from "vue";
import InstantSearch from "vue-instantsearch";
import { instantMeiliSearch } from "@meilisearch/instant-meilisearch";

// Use the InstantSearch plugin
Vue.use(InstantSearch);

// Create another instance of the MeiliSearch client with custom settings (for testing)
const meiliClient = instantMeiliSearch(
  process.env.NUXT_ENV_SEARCH_API_URL, // Set the MeiliSearch API URL
  process.env.NUXT_ENV_SEARCH_API_KEY, // Set the MeiliSearch API Key
  { placeholderSearch: false, finitePagination: true }
);

// Define a search client that extends the MeiliSearch client and handles empty query requests
const searchClient = {
  ...meiliClient,  // Include the MeiliSearch client's methods and settings
  search(requests) {
    if (requests.every(({ params }) => !params.query)) {
      // Handle empty query requests by returning an empty response
      return Promise.resolve({
        results: requests.map(() => ({
          hits: [],
          nbHits: 0,
          nbPages: 0,
          page: 0,
          processingTimeMS: 0,
          hitsPerPage: 0,
          exhaustiveNbHits: false,
          query: "",
          params: "",
        })),
      });
    }
    // Perform a search using the MeiliSearch client
    return meiliClient.search(requests);
  },
};

// Make the search client and testing client available globally through Vue prototypes
Vue.prototype.$searchClient = searchClient;
