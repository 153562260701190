
import lang from "@/mixins/lang";
export default {
  name: "TopBar",
  mixins: [lang],
  props: {
    topbar: {
      type: Object,
    },
    global: {
      type: Object,
    },
  },
};
